import { Box, Center, Heading, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react"
import * as React from "react"

interface LoginCardProps {
  termsLink?: string
  title?: React.ReactNode
}

export const LoginCard: React.FunctionComponent<LoginCardProps> = ({
  termsLink = "/tos",
  title = (
    <Center>
      <Stack direction="row">
        <Heading pt="2" fontWeight="200" color="brand.100">
          Niftory Sign In
        </Heading>
      </Stack>
    </Center>
  ),
}) => {
  return (
    <Box bg={useColorModeValue("white", "gray.800")} maxW="2xl" mx="auto" rounded="2xl" p="8">
      <Stack maxW="sm" mx="auto" spacing="4">
        {title}
      </Stack>
      <Text
        mt="16"
        fontSize="xs"
        mx="auto"
        maxW="md"
        textAlign="center"
        color={useColorModeValue("gray.600", "gray.400")}
      >
        By continuing, you acknowledge that you have read, understood, and agree to our{" "}
        <Link href={termsLink} isExternal color="page.accent">
          terms of service
        </Link>
      </Text>
    </Box>
  )
}
