import { Button } from "@chakra-ui/react"
import { useAuthContext } from "src/hooks/core/useAuthContext"

export function LoginButton() {
  const { signIn } = useAuthContext()

  return (
    <Button
      fontSize="sm"
      fontWeight="bold"
      size="lg"
      iconSpacing="3"
      colorScheme="red"
      width="full"
      onClick={() => signIn()}
    >
      Sign in
    </Button>
  )
}
