import { Box, Center, Heading, Stack, Text } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { LoginButton } from "src/components/auth/LoginCard/LoginButton"
import { LoginCard } from "src/components/auth/LoginCard/LoginCard"
import { LoginSkeleton } from "src/components/ui/Skeleton"
import { useAuthContext } from "src/hooks/core/useAuthContext"

const Login = () => {
  const { session, isLoading, signIn } = useAuthContext()
  const signedIn = !!session
  const router = useRouter()

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (signedIn) {
      router.push("/app/profile")
    } else {
      signIn(router.query?.redirectURL as string)
    }
  }, [isLoading, router, signedIn, signIn])

  if (isLoading || signedIn) {
    return <LoginSkeleton />
  }

  return (
    <Box
      as="section"
      height="92vh"
      bg="black"
      bgSize="contain"
      pt={{ base: "15vh", sm: "25vh" }}
      px="1em"
    >
      <LoginCard
        title={
          <>
            <Center>
              <Stack direction="row">
                <Heading pt="2" fontWeight="200" color="brand.100">
                  Niftory
                </Heading>
              </Stack>
            </Center>
            <Stack spacing="3">
              <Heading textAlign="center" as="h1" fontWeight="200" letterSpacing="tight">
                launch your collection
              </Heading>
              <Text textAlign="center" fontWeight="light" color="gray.600">
                Sign-in to manage your organization, content, and more.
              </Text>
              <LoginButton />
            </Stack>
          </>
        }
      />
    </Box>
  )
}

export default Login
